import React from 'react'
import Contacts from '../contacts'


const ContactsPage = () => {
  return (
    <>
      <Contacts />
    </>
  );
};

export default ContactsPage