import React from 'react'

import Help from '../help'
import Donation from '../donation'



const HelpPage = () => {
  return (
    <React.Fragment>
      <Help />
      <Donation />
      
    </React.Fragment>
  );
};

export default HelpPage